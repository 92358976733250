// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as ActiveStorage from "@rails/activestorage";
import { application } from "@controllers/application";
import ProfileController from "@controllers/common/profile/profile";
import PrimaryNavigationController from "@controllers/common/primary-navigation/primary-navigation";
import "channels";

application.register("profile", ProfileController);
application.register("navigation", PrimaryNavigationController);

let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

// Putting this here as it's GOT to be included in thge buld.

String.EMPTY_STRING = "";

ActiveStorage.start();
